import type { GatsbyBrowser } from "gatsby";

declare global {
  interface Window {
    plausible?: (event: string, options?: Record<string, any>) => void;
  }
}

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  if (window.plausible) {
    window.plausible("pageview", { props: { path: location.pathname } });
  }
};

export { wrapPageElement, wrapRootElement } from "./gatsby-shared";